.myBtn {
    position: relative;
    padding: 12px 16px;
    width: 140px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 4px;
}

.green{
    composes: myBtn;
    background-color: #7fffb2;
}
.red{
    composes: myBtn;
    background-color: #ff7f7f;
}

.myBtn:hover {
    box-shadow: 1px 1px 3px;
}
