.card{
    position:relative;
    padding: 20px;
    border-radius: 8px;
    border:1px solid black;
    background-color: #fff;
}
.btnWrp {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.detailesWrp{
    margin-top: 20px;
    display: flex;
    gap: 20px;
}

.detailes{
    font-size: 18px;
}

.fetchDetailesWrp{
    margin-top: 20px;
}