.formWrp{
    padding-top: 24px;
    padding-bottom: 24px;
}
.form{
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
}
.title{
    font-size: 24px;
    text-align: center;
    margin-bottom: 24px;
}
